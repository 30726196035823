<script lang="ts">
	import { Router, Route } from "svelte-routing";
	import Credits from "./Credits.svelte";
	import EditForm from "./EditForm.svelte";
	export let url: string;
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	let broadcasterId = urlParams.get("broadcaster_id");
	let getCreditsData: Promise<any> = fetch("/credits/" + broadcasterId)
		.then((res) => {
			if (!res.ok) {
				throw new Error("Failed to retrieve credits data");
			}
			return res.json();
		})
		.then((data) => {
			return data;
		})
		.catch((err) => {
			console.error(err);
		});
	let getUserData: Promise<any> = fetch("/user/" + broadcasterId)
		.then((res) => {
			if (!res.ok) {
				throw new Error("Failed to retrieve user data");
			}
			return res.json();
		})
		.then((data) => {
			return data;
		})
		.catch((err) => {
			console.error(err);
		});
</script>

<Router {url}>
	<Route path="/">
		<main>
			{#await getCreditsData}
				<p>Loading...</p>
			{:then data}
				<Credits {data} />
			{:catch error}
				<p>Error: {error}</p>
			{/await}
		</main>
	</Route>
	<Route path="/edit">
		{#await getUserData}
			<p>Loading...</p>
		{:then user}
			<EditForm {user} />
		{:catch error}
			<p>Error: {error}</p>
		{/await}
	</Route>
</Router>

<style type="text/css">
	main {
		height: 100%;
		width: 100%;
	}
</style>
