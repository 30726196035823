<script lang="ts">
  import { onMount } from "svelte";
  export let user;
  let broadcasterId: string;
  onMount(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    broadcasterId = urlParams.get("broadcaster_id");
  });
  function saveUserDetails() {
    let elements = [
      "footer_message=" + encodeURIComponent(user.footer_message ?? ""),
      "patreon_token=" + encodeURIComponent(user.patreon_token ?? ""),
      "streamelements_channel=" +
        encodeURIComponent(user.streamelements_channel ?? ""),
      "streamelements_token=" +
        encodeURIComponent(user.streamelements_token ?? ""),
      "label_subscriptions=" +
        encodeURIComponent(user.label_subscriptions ?? ""),
      "label_bits=" + encodeURIComponent(user.label_bits ?? ""),
      "label_donations=" + encodeURIComponent(user.label_donations ?? ""),
    ];
    let formBody = elements.join("&");
    fetch("/user/" + broadcasterId, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to save user data");
        }
      })
      .then(() => {
        console.log("Saved user data");
      })
      .catch((err) => {
        console.error(err);
      });
  }
</script>

<div class="max-w-md mx-auto bg-white shadow-lg rounded-lg p-6">
  <form on:submit|preventDefault={saveUserDetails}>
    <div class="mb-4">
      <label class="block text-gray-700 font-bold mb-2" for="username">
        Username
      </label>
      <input
        class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="username"
        type="text"
        placeholder="Username"
        bind:value={user.username}
      />
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 font-bold mb-2" for="patreon_token">
        Patreon Token
      </label>
      <input
        class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="patreon_token"
        type="password"
        bind:value={user.patreon_token}
      />
    </div>
    <div class="mb-4">
      <label
        class="block text-gray-700 font-bold mb-2"
        for="streamelements_channel"
      >
        Stream Elements Channel
      </label>
      <input
        class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="streamelements_channel"
        type="text"
        bind:value={user.streamelements_channel}
      />
    </div>
    <div class="mb-4">
      <label
        class="block text-gray-700 font-bold mb-2"
        for="streamelements_token"
      >
        Stream Elements Token
      </label>
      <input
        class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="streamelements_token"
        type="password"
        bind:value={user.streamelements_token}
      />
    </div>
    <div class="mb-4">
      <label
        class="block text-gray-700 font-bold mb-2"
        for="label_subscriptions"
      >
        Subscribers Label
      </label>
      <input
        class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="label_subscriptions"
        type="text"
        placeholder="Subscribers"
        bind:value={user.label_subscriptions}
      />
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 font-bold mb-2" for="label_bits">
        Bits/Cheers Label
      </label>
      <input
        class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="label_bits"
        type="text"
        placeholder="Bits"
        bind:value={user.label_bits}
      />
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 font-bold mb-2" for="label_donations">
        Donations/Tips Label
      </label>
      <input
        class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="label_donations"
        type="text"
        placeholder="Donations"
        bind:value={user.label_donations}
      />
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 font-bold mb-2" for="footer_message">
        Footer Text
      </label>
      <textarea
        class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="footer_message"
        rows="3"
        placeholder="Enter footer text"
        bind:value={user.footer_message}
      />
    </div>
    <button
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      type="submit">Save</button
    >
  </form>
</div>
