<script lang="ts">
    export let data;
    function convertPatronsToTiers(patronList) {
        let tiers = {};
        patronList.forEach((el) => {
            if (el.category in tiers == false) {
                tiers[el.category] = [];
            }
            tiers[el.category].push(el);
        });
        return Object.keys(tiers).map((key) => {
            return {
                name: key,
                patrons: tiers[key],
            };
        });
    }
    function toDollars(cents) {
        let amount = cents / 100;
        return amount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
    }
    $: patreonLink = data.metadata.patreon_link;
    $: donations = data.supporters.filter((x) => x.type == "donation");
    $: subscribers = data.supporters.filter((x) => x.type == "subscriber");
    $: bits = data.supporters.filter((x) => x.type == "bits");
    $: patronList = data.supporters.filter((x) => x.type == "patreon");
    $: patrons = convertPatronsToTiers(patronList);
    $: hasTwitch =
        subscribers.length > 0 || bits.length > 0 || donations.length > 0;
    $: hasPatreon = patronList.length > 0;
</script>

<div class="scroll-container">
    {#if subscribers.length > 0}
        <h2>{data.metadata.label_subscriptions}</h2>
        <ul>
            {#each subscribers as entry}
                <li>{entry.name}</li>
            {/each}
        </ul>
    {/if}
    {#if Object.keys(bits).length > 0}
        <h2>{data.metadata.label_bits}</h2>
        <ul>
            {#each bits as entry}
                <li>{entry.name} ({entry.amount})</li>
            {/each}
        </ul>
    {/if}
    {#if Object.keys(donations).length > 0}
        <h2>{data.metadata.label_donations}</h2>
        <ul>
            {#each donations as entry}
                <li>{entry.name} ({toDollars(entry.amount)})</li>
            {/each}
        </ul>
    {/if}
    {#if hasPatreon}
        {#if hasTwitch}
            <p>And special thanks to all my patrons...</p>
        {/if}
        {#each patrons as tier}
            <h2>{tier.name}</h2>
            <ul>
                {#each tier.patrons as entry}
                    <li>{entry.name}</li>
                {/each}
            </ul>
        {/each}
        <p>
            To become a patron of mine, visit <a href={patreonLink}
                >{patreonLink}</a
            >
        </p>
        <p>{data.metadata.footer_message}</p>
    {/if}
</div>

<style type="text/css">
    a {
        color: white;
        text-decoration: underline;
    }
    h2 {
        font-size: 1.25em;
        text-decoration: underline;
    }
    li {
        padding-bottom: 1em;
    }
    p:last-child {
        padding-bottom: 6em;
    }
    .scroll-container {
        animation: scroll-up 45s linear infinite;
        color: white;
        font-family: Aniron, Arial, Helvetica, sans-serif;
        font-size: 2em;
        min-height: 100%;
        text-align: center;
        text-shadow: 3px 3px black;
        transform: translateY(-100%);
    }
    ul {
        column-count: 3;
        list-style-type: none;
        margin: 0;
        padding: 0 0 4em;
    }
    @keyframes scroll-up {
        from {
            transform: translateY(25%);
        }
        to {
            transform: translateY(-100%);
        }
    }
</style>
